<template>
  <v-container class="pt-1">
    <error-dialog
      message="Error! Try again."
      :showDialog.sync="searchError"
    ></error-dialog>
    <hierarchy-tree-filter
      ref="filters"
      :results="entities"
      :totalResults="numberOfChildren"
      :refresh="refresh"
    ></hierarchy-tree-filter>
    <v-card>
      <v-data-table
        fixed-header
        class="scrollable-table__large table-header_align"
        :headers="selectedHeaders"
        :items="entities"
        :loading="loading"
        :options.sync="options"
        :server-items-length="numberOfChildren"
        item-key="id"
        :single-select="true"
        v-model="selected"
        @click:row="selectRow"
        @dblclick:row="openRow"
        :hide-default-footer="numberOfChildren == 0"
        :footer-props="{
          'items-per-page-options': rowsPerPage,
          disablePagination: loading,
          disableItemsPerPage: loading,
        }"
      >
        <template v-slot:progress>
          <v-progress-linear
            :color="colorTheme.progress"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <cell-clipboard :text="item.id" />
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <a href="#" @click.prevent="columnNameOpenRow(item)">
            <router-link
              :to="{
                name: 'HierarchyTree',
                params: {
                  hierarchy: hierarchyTag.address,
                  id: item.id
                }
              }"
            >
              {{ item.name }}
            </router-link>
          </a>
        </template>
        <template v-slot:[`item.modelName`]="{ item }">
          <td v-if="item.type.toLowerCase() == 'sleeve'">
            {{ getModelNameForSleeve(item) }}
          </td>
          <td v-else>{{ item.modelName }}</td>
        </template>
        <template v-slot:[`item.modelId`]="{ item }">
          <cell-clipboard :text="item.modelId" />
        </template>
        <template v-slot:[`item.managerId`]="{ item }">
          <cell-clipboard :text="item.managerId" />
        </template>
        <template v-slot:[`item.billingStartDate`]="{ item }">
          <date-cell :value="item.billingStartDate" />
        </template>
        <template v-slot:[`item.billingEndDate`]="{ item }">
          <date-cell :value="item.billingEndDate" />
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" :color="colorTheme.alert">
            {{ noDataText }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { equalObjects } from "@/utils/object-operations.js";

import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import CellClipboard from "@/components/common/CellClipboard.vue";
import amplitude from 'amplitude-js';
import HierarchyTreeFilter from "@/components/filters/HierarchyTreeFilter.vue";
import DateCell from "@/components/common/DateCell.vue";

export default {
  props: ["hierarchy", "id"],
  components: {
    ErrorDialog,
    CellClipboard,
    HierarchyTreeFilter,
    DateCell,
  },
  data: () => ({
    numberOfLoadings: 0,
    loading: false,
    searchError: false,
  }),
  computed: {
    ...mapState("hierarchyMode", ["hierarchyTag"]),
    ...mapState("browseEntities", ["entities", "numberOfChildren"]),
    ...mapState("loading", ["elementsLoading"]),
    ...mapState("hierarchyTree", [
      "selectedEntities",
      "pagination",
      "selectedHeaders"
    ]),
    ...mapState("directoryNavigation", ["navigation", "navigationSelected"]),
    ...mapState("userConfig", ["colorTheme", "rowsPerPage"]),
    options: {
      get() {
        return this.pagination;
      },
      set(newValue) {
        this.setPagination(newValue);
      },
    },
    page() {
      return this.options.page;
    },
    pageSize() {
      return this.options.itemsPerPage;
    },
    sortBy() {
      if (this.options.sortBy && this.options.sortBy.length === 1) {
        return this.options.sortBy[0];
      } else {
        return null;
      }
    },
    sortDirection() {
      if (this.options.sortDesc && this.options.sortDesc.length === 1) {
        return this.options.sortDesc[0] === false ? "Ascending" : "Descending";
      } else {
        return "Ascending";
      }
    },
    selected: {
      get() {
        return this.selectedEntities;
      },
      set(newValue) {
        this.setSelectedEntities(newValue);
      },
    },
    noDataText() {
      if (this.$refs.filters.$refs.searchFilter.filtersActive) {
        return "No results...";
      }
      if (this.navigation.length <= 1) {
        return "Loading root element...";
      } else if (this.numberOfChildren == 0) {
        return "You have reached the lowest level in the hierarchy";
      } else {
        return "Loading items...";
      }
    },
    quickSearchValue() {
      return this.$refs.filters.$refs.searchFilter.quickSearchValue;
    },
  },
  watch: {
    options: {
      deep: true,
      handler(val, oldVal) {
        if (!this.$cookies.isKey("token")) {
          return;
        }
        if (
          !equalObjects(val.sortBy, oldVal.sortBy) ||
          !equalObjects(val.sortDesc, oldVal.sortDesc)
        ) {
          this.refresh();
        } else if (val.page !== oldVal.page) {
          this.refresh();
        } else if (val.itemsPerPage !== oldVal.itemsPerPage) {
          amplitude.getInstance().logEvent("Hierarchy Tree [Rows Per Page]", {
            "Number of rows selected": this.options.itemsPerPage
          });
          this.refreshOrCut(val.itemsPerPage);
        }
      },
    },
  },
  methods: {
    ...mapMutations("auth", ["resetNumOfFails"]),
    ...mapMutations("browseEntities", ["setEntities"]),
    ...mapMutations("hierarchyTree", [
      "setSelectedEntities",
      "setPagination",
      "resetPage",
    ]),
    ...mapMutations("directoryNavigation", [
      "setNavigationSelected",
      "setNavigation",
      "removeLastFromNavigation",
    ]),
    ...mapActions("directoryNavigation", [
      "addNavigationItem",
      "jumpToNavigationItem",
    ]),
    ...mapActions("browseEntities", [
      "setDefaultValues",
      "hierarchyTreeSearch",
    ]),
    ...mapActions("hierarchyTree", ["resetSelected"]),
    async refreshOrCut(pageSize) {
      let array = this.entities;
      if (pageSize > array.length) {
        await this.refresh();
      } else {
        this.setEntities(this.entities.slice(0, pageSize));
      }
    },
    columnNameOpenRow(item) {
      // Find the index by comparing the item ID with the table data
      const index = this.entities.findIndex(entity => entity.id === item.id);
      if (index !== -1) {
        const row = document.querySelectorAll('.v-data-table tbody tr')[index];
        if (row) {
          row.dispatchEvent(new MouseEvent('dblclick', { bubbles: true }));
        }
      } else {
        console.error('Index not found');
      }
    },
    selectRow(event, payload) {
      try {
        if (!this.elementsLoading && !this.loading) {
          const selectedItem = payload.item;
          if (this.navigationSelected == null) {
            this.addNavigationItem({
              id: selectedItem.id,
              text: selectedItem.name,
              type: selectedItem.type,
              disabled: false,
            });
          } else {
            this.setNavigation(
              this.navigation.filter((a) => a.id != this.navigationSelected.id)
            );
            this.addNavigationItem({
              id: selectedItem.id,
              text: selectedItem.name,
              type: selectedItem.type,
              disabled: false,
            });
          }
          this.setNavigationSelected(selectedItem);
          this.selected = [];
          this.selected.push(selectedItem);
        }
      } catch (error) {
        this.refreshCustomError(error);
      }
    },
    async openRow(event, payload) {
      if (!this.elementsLoading && !this.loading) {
        try {
          const openItem = payload.item;
          this.loading = true;
          await this.jumpToNavigationItem(openItem);
          this.setNavigationSelected(null);
          this.loading = false;
        } catch (error) {
          this.refreshCustomError(error);
        }
      }
    },
    refreshCustomError(error) {
      this.showCustomError.refresh(error);
    },
    async refreshOnMount() {
      this.numberOfLoadings++;
      this.loading = true;
      let id = this.id === "root" ? "" : this.id;
      await this.jumpToNavigationItem({ id: id });
      this.numberOfLoadings--;
      if (this.numberOfLoadings == 0) {
        this.loading = false;
      }
    },
    async refresh(params) {
      this.numberOfLoadings++;
      this.loading = true;
      this.resetSelected();
      if (this.navigationSelected) {
        this.removeLastFromNavigation();
      }

      if (params && params.page) {
        this.options.page = params.page;
      }
      let payload = {
        entityId: this.id,
        page: this.page,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        searchParam: this.quickSearchValue
      };
      let result = await this.hierarchyTreeSearch(payload);
      if (!result) {
        this.searchError = true;
      }

      this.numberOfLoadings--;
      if (this.numberOfLoadings == 0) {
        this.loading = false;
      }
    },
    getModelNameForSleeve(entity) {
      if (entity.name.includes("Model Sleeve")) {
        return entity.modelName;
      } else {
        return null;
      }
    },
  },
  async mounted() {
    this.resetNumOfFails();
    let result = await this.setDefaultValues({
      tabName: "HierarchyTree",
      hierarchy: this.hierarchy,
      id: this.id,
    });

    if (result) {
      await this.refreshOnMount();
    }
  }
};
</script>

<style>
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #4ac5b9 !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #4ac5b9 !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected:hover {
  background: #4ac5b9 !important;
}
.theme--light.v-data-table tbody tr.v-data-table__selected:hover {
  background: r#4ac5b9 !important;
}
</style>
